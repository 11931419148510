/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import { Button } from '@interco/inter-ui/components/Button'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import * as S from './styles'

import { primary } from 'src/styles/newColors'

const RewardsProgramHeader = () => {
  const [ iWb, setIWb ] = useState<typeof import("@interco/inter-webview-bridge")>()

  useEffect(() => {
    import('@interco/inter-webview-bridge').then((interBridge: typeof import("@interco/inter-webview-bridge")) => {
      setIWb(interBridge)

      const bridgeInstance = interBridge.default.getInstance()
      bridgeInstance.addWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
      return () => bridgeInstance.removeWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
    })
  }, [])

  return (
    <S.Header>
      <AppBar
        leftIcon={
          <Button variant='link' onClick={() => iWb?.interWbNavigate.requestGoBack()}>
            <IconsSwitch color={primary[500]} width='24' height='24' icon='action-navigation/ic_arrow_left' lib='interco' />
          </Button>
      }
      >
        Programa de recompensas
      </AppBar>
    </S.Header>
  )
}

export default RewardsProgramHeader
