import React from 'react'
import * as S from './style'

const Hero = () => {
  return (
    <S.Section className='py-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex justify-content-center font-inter'>
              <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/webview-forum/image.webp' />
            </div>
            <h2 className='fs-24 fw-600 mt-3'>Crie conteúdos e ganhe benefícios exclusivos!</h2>
            <p className='fs-14 lh-18 fw-400 mt-3'>Você pode ser recompensado por criar conteúdos relevantes no Forum. Quanto mais você criar, mais você ganha.</p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
