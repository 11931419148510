const defaultTheme = {
  textColorWhite: "var(--white)",
  textColorGray100: "var(--gray100)",
  textColorGray200: "var(--gray200)",
  textColorGray400: "var(--gray400)",
  textColorGray500: "var(--gray500)",
  textColorOrange500: "var(--orange500)",
  textColorGreen400: "var(--green400)",
  textColorRed: "var(--red500)",
  backgroundColorWhite: "var(--white)",
  backgroundColorGray100: "var(--gray100)",
  backgroundColorGray500: "var(--gray500)",
}

export default defaultTheme
