/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { initTheme, Theme } from '@interco/inter-ui'

import useDomReady from 'src/hooks/window/useDomReady'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms'
import ModalMenssage from '../ModalMenssage/_index'

import { Modal } from 'src/components/Modal'

interface IFormValues {
  nome: string;
  email: string;
  cpfCnpj: string;
  conteudo01: string;
}

export type Form = {
  title: string;
  body: string;
  detailsList: {
    title: string;
    body: string;
    icon: string;
  }[];
  ctaText: string;
  ctaLink: string;
  adjustLink: string;
}

type FormBottomSheetProps = {
  setShowBottomSheet: Function;
}

const FormBottomSheet = ({ setShowBottomSheet }: FormBottomSheetProps) => {
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const domReady = useDomReady()
    const [ accept, setAccept ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const { register, errors, handleSubmit }: UseFormMethods<IFormValues> = useForm<IFormValues>()
    const [ openModal, setOpenModal ] = useState(false)
    const [ type, setType ] = useState('success')
    const [ openSelect, setOpenSelect ] = useState(false)

    const formSubmit = async (data: IFormValues) => {
      setLoading(true)

      const formData = {
        ...data,
        aceite: accept,
        campanha: 'Recompensas Forum',
      }

      try {
        await axios.post(`${URLs.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        })
        setOpenSelect(false)
        setType('success')
        setAccept(false)
        setLoading(false)
        setOpenModal(true)
      } catch (err) {
        setLoading(false)
        setType('error')
        setOpenModal(true)
      }
    }

    const ModalMenssagem = domReady && (
      <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
        <ModalMenssage type={type} setOpen={setOpenModal} setShowBottomSheet={setShowBottomSheet} />
      </Modal>
    )

  return (
    <S.FormBottomSheet>
      {
        ModalMenssagem
      }
      <h1 className='investments__title'>Programa de recompensas</h1>
      <p className='fs-14'>Para se inscrever preencha os campos abaixo.</p>
      <S.Card isOpenSelect={openSelect}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className='row'>
            <div className={`col-12 ${errors.nome && 'error'}`}>
              <label className='fs-12 lh-14 mt-3 mb-1 d-block'>Nome</label>
              <input
                placeholder='Digite seu nome completo'
                type='text'
                name='nome'
                ref={register({
                required: 'Por favor, digite seu nome completo',
                validate: {
                  isName: (value: string) => Validations.validFullName(value) || 'Por favor, digite seu nome completo',
                },
              })}
              />
              {errors.nome && <p className='fs-12  mb-0 text-right'>{errors.nome.message}</p>}
            </div>
            <div className={`col-12 ${errors.email && 'error'}`}>
              <label className='fs-12 lh-14 d-block mt-2 mb-1'>E-mail</label>
              <input
                placeholder='Digite seu melhor e-mail'
                type='text'
                name='email'
                ref={register({
                required: 'Digite um e-mail válido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              />
              {errors.email && <p className='fs-12  mb-0 text-right'>{errors.email.message}</p>}
            </div>
            <div className={`col-12 ${errors.conteudo01 && 'error'}`}>
              <label className='fs-12 lh-14 d-block mt-2 mb-1'>@ do forum</label>
              <input
                placeholder='Digite seu @ do forum'
                type='text'
                name='conteudo01'
                ref={register({
                  required: 'Por favor, digite seu @ do forum',
                })}
              />
              {errors.conteudo01 && <p className='fs-12  mb-0 text-right'>{errors.conteudo01.message}</p>}
            </div>
            <div className='col-12 mt-2'>
              <AcceptTerms
                accept={accept}
                setAccept={setAccept}
                name='aceite'
                styles='fs-10 lh-12 accept-terms'
                linkColor='#ffffff'
                label='Quero receber informações sobre o Programa de Recompensas do Forum. Os dados pessoais serão tratados para envio de comunicações de produtos e serviços, de acordo com a'
                acceptLink='https://marketplace.bancointer.com.br/termos/Regulamento_Campanha_Teste_Drive_Premiado_Inter_Cel_27062024.pdf'
              />
              <button
                type='submit'
                title='Continuar'
                disabled={!accept || loading}
                className='btn btn--lg bg-orange--base text-white text-none mt-4'
              >
                {loading ? 'Enviando...' : 'Continuar' }
              </button>
              <button
                title='Voltar'
                onClick={() => setShowBottomSheet(false)}
                className='btn btn--lg bg-white text-orange--base btn--outline text-none mt-4'
              >
                voltar
              </button>
            </div>
          </div>
        </form>
      </S.Card>
    </S.FormBottomSheet>
  )
}

export default FormBottomSheet
