import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: ${ (props: { theme: { backgroundColorWhite: string } }) => props.theme.backgroundColorWhite };
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  
  h3 {
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
  }

  p {
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
  }

  .content-modal {
    padding: 24px 40px 40px;
  }

  @media (min-width: ${breakpoints.md}){
    max-width: 372px;
  }

  img{
    margin: 50px auto 30px;
    display: block;
  }

  .relative{
    position: relative;
    min-height: 100% ;
    padding: 24px 40px 40px;
    display: contents;
  }
`

export const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  background:  none;
  padding: 0;
  border: none;
  position: absolute;
  right: 16px;
  top: 16px;

  &:focus{
    outline: none;
  }
`
