import styled from 'styled-components'

export const Header = styled.header`
  & span {
    font-family: 'Inter', Helvetica, sans-serif !important;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  button {
    padding: 0.25rem;
  }

  .help-btn-placeholder {
    height: 32px;
    width: 32px;
  }
`
