import React, { useState, useEffect } from 'react'
import ScrollTo from 'src/components/ScrollTo'

import Success from '../../../../assets/images/success.png'
import Error from '../../../../assets/images/error.png'
import Close from '@interco/icons/build-v4/bidis/v2/navigation/close'

import * as S from './style'

const menssagens = {
  error: {
    title: 'Não foi possível receber suas informações',
    paragraph: '<p class="fs-14 lh-17 text-center">Ocorreu um erro e não conseguimos concluir sua manifestação de interesse no Programa de Recompensas do Forum. Atualize a página e, caso o erro persista, tente novamente mais tarde.</p>',
  },
  success: {
    title: 'Agradecemos seu interesse no nosso Programa de Recompensas',
    paragraph: '<p class="fs-14 lh-17">Assim que o programa estiver disponível você será avisado. Em breve, enviaremos mais novidades!</p>',
  },
}

type ModalMensssageProps = {
  type: string;
  setOpen: Function;
  setShowBottomSheet: Function;
}

const ModalMenssage = ({ setOpen, type, setShowBottomSheet }: ModalMensssageProps) => {
  const handleClick = () => {
    setOpen(false)
    setShowBottomSheet(false)
  }
    const [ iWb, setIWb ] = useState<typeof import("@interco/inter-webview-bridge")>()

    useEffect(() => {
      import('@interco/inter-webview-bridge').then((interBridge: typeof import("@interco/inter-webview-bridge")) => {
        setIWb(interBridge)

        const bridgeInstance = interBridge.default.getInstance()
        bridgeInstance.addWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
        return () => bridgeInstance.removeWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
      })
    }, [])

    const backToForumLink = 'bancointer://intersocial/home'

  return (
    <S.Modal>
      <div className='relative'>
        <S.CloseButton onClick={() => handleClick()}>
          <ScrollTo
            to='#hero-inter-day'
            section='dobra_01'
            elementName='Entendi'
            sectionName='Inter Day 2024'
          >
            <Close height={24} width={24} color='#FF7A00' />
          </ScrollTo>
        </S.CloseButton>
        <div className='content-modal'>
          {
            type === 'success' ? <img src={Success} /> : <img src={Error} />
          }
          <h3
            className='fs-20 lh-28 fw-600 text-center mt-3'
            dangerouslySetInnerHTML={{ __html: type === 'success' ? menssagens.success.title : menssagens.error.title }}
          />
          <p
            className='fs-14 lh-17 text-center'
            dangerouslySetInnerHTML={{ __html: type === 'success' ? menssagens.success.paragraph : menssagens.error.paragraph }}
          />

          {type === 'success'
            ? <a
                href='#'
                onClick={() => iWb?.interWbNavigate.openDeepLink(backToForumLink)}
                title='Quero me inscrever'
                className='btn btn--lg bg-orange--base text-white text-none mt-4'
              >
              Voltar para o Forum
            </a> :
            <div>
              <button
                onClick={() => setOpen(false)}
                title='Quero me inscrever'
                className='btn btn--lg bg-orange--base text-white text-none mt-4'
              >
                Tentar novamente
              </button>
              <a
                href='#'
                onClick={() => iWb?.interWbNavigate.openDeepLink(backToForumLink)}
                title='Quero me inscrever'
                className='btn btn--lg text-orange--base text-none mt-4'
              >
                Voltar para o Forum
              </a>
            </div>
          }
        </div>
      </div>
    </S.Modal>
  )
}

export default ModalMenssage
