import styled from 'styled-components'

export const Section = styled.section`
  border-bottom: 8px solid ${ (props: { theme: { textColorGray100: string } }) => props.theme.textColorGray100 };

  h2 {
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
  }
  
  p {
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
  }


`
