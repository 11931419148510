import styled from "styled-components"
import { device } from 'src/styles/breakpoints'

type DataProps = {
  isOpenSelect?: boolean;
}

export const FormBottomSheet = styled.section`
  .investments__title {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0.5rem;
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
  }

  .investments__body {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
    margin-bottom: 1.5rem;
  }

  .investments-list__title {
    font-family: 'Sora', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
    margin-bottom: 0.5rem;
  }

  .investments-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1.5rem;
  }

  .investments-list__item {
    display: grid;
    grid-template-columns: 24px minmax(272px, 3fr);
    gap: 1rem;
    padding: 1rem 0;

    &>div {
      align-self: center;
    }

    svg {
      width: 24px;
      height: 24px;
      align-self: center;
      justify-self: start;
      fill: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${ (props: { theme: { textColorGray200: string } }) => props.theme.textColorGray200 };;
    }
  }

  .investments-list__item__title {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
    margin-bottom: 0.25rem;
  }

  .investments-list__item__body {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
    margin-bottom: 0;
  }

  .investments__link {
    text-decoration: none;
    display: block;
    width: 100%;
    height: fit-content;
  }
`

export const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media ${device.desktopLG} {
    background-color: ${ (props: { theme: { backgroundColorGray500: string } }) => props.theme.backgroundColorGray500 };
    border-radius: 16px;
  }

  .input {
    label {
      color: ${ (props: { theme: { textColorWhite: string } }) => props.theme.textColorWhite };
    }
  }

  .accept_terms {
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: ${ (props: { theme: { textColorWhite: string } }) => props.theme.textColorWhite };

    u {
      color: ${ (props: { theme: { textColorWhite: string } }) => props.theme.textColorWhite };
      text-decoration: none;
    }
  }
`

export const Card = styled.div<DataProps>`
  width: 100%;
  position: relative;
  margin: 0 auto;

  .accept-terms {
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
    u {
      color: ${ (props: { theme: { textColorOrange500: string } }) => props.theme.textColorOrange500 };
    }
  }

  label {
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
  }

  @media ${device.tablet} {
    padding: 24px;
    float: right;
    z-index: ${(props: DataProps) => props.isOpenSelect ? 10 : 1};
  }
  @media ${device.desktopLG} {
    max-width: 479px;
  }
  @media ${device.desktopXL} {
    margin-bottom: 0;
  }

  input {
    height: 48px;
    width: 100%;
    border: none;
    padding-left: 10px;
    border-radius: 8px;
    color: ${ (props: { theme: { textColorGray300: string } }) => props.theme.textColorGray300 };
    background: ${ (props: { theme: { backgroundColorGray100: string } }) => props.theme.backgroundColorGray100 };

    &:focus{
      outline:none;
    }

    &::placeholder{
      font-size: 14px;
      line-height: 18px;
      color: ${ (props: { theme: { textColorGray300: string } }) => props.theme.textColorGray300 };
    }
  }

  .error {
    label, p {
      color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
    }
    input, details {
      background: ${ (props: { theme: { backgroundColorGray100: string } }) => props.theme.backgroundColorGray100 };

      
      ::placeholder {
      color: ${ (props: { theme: { textColorRed: string } }) => props.theme.textColorRed };
      }
    }
    summary {
      p {
        color: ${ (props: { theme: { textColorRed: string } }) => props.theme.textColorRed }; !important;
      }
    }

    button {
      span {
        color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 }; !important;
      }
    }
  }
`
